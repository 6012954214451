import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

export interface ApplicationState {
  title: string;
  message: string;
  value: string|number;
  display: boolean;
  position: string;
  timeCount: number;
}
const initialState: ApplicationState = {
  title: "",
  message: "",
  value: 1,
  display: false,
  position: "rightBottom",
  timeCount: 5000,
};
@Injectable({
  providedIn: "root",
})
export class ExportStoreService {
  private readonly store$ = new BehaviorSubject<ApplicationState>(initialState);
  readonly toastData$ = this.store$.pipe(map((state) => state));
  public displayDownload = true;

  setDownload(title:any, message: any, timeCount = 750000, position = "rightBottom") {
    this.store$.next({
      ...this.store$.value,
      title,
      message,
      display: this.displayDownload,
      position,
      timeCount,
    });
    setTimeout(() => {
      this.clearStore();
    }, timeCount);
  }

  setComplete(title:any, message: any, timeCount = 5000, position = "rightBottom") {
    this.store$.next({
      ...this.store$.value,
      title,
      message,
      display: true,
      position,
      timeCount,
    });
    setTimeout(() => {
      this.clearStore();
    }, timeCount);
  }

  setError(message: string, position = "rightBottom", timeCount = 5000) {
    this.store$.next({
      ...this.store$.value,
      title: "Error occurred",
      message,
      display: true,
      position,
      timeCount,
    });
    setTimeout(() => {
      this.clearStore();
    }, this.store$.value.timeCount);
  }

  clearStore() {
    this.store$.next({
      ...this.store$.value,
      message: "",
      display: false,
      title: "",
      position: "rightBottom",
      timeCount: 5000,
    });
    this.displayDownload = true;
  }
}
